<template>
  <div>
    <!-- Add New Sidebar -->
    <rule-group-sidebar
      :is-rule-group-sidebar-active.sync="isRuleGroupSidebarActive"
      @updated="queryGroups"
    />

    <b-card title="Filter">
      <b-row>
        <b-col
          md="6"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            v-model="ruleGroupStatusFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="ruleGroupStatusOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>

        <b-col
          md="6"
          class="mb-md-0 mb-2"
        >
          <label>Search Key</label>
          <b-form-input
            id="searchKeyInput"
            v-model="searchKey"
            placeholder="Key Word Here..."
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="mb-md-0 mb-2 mt-1"
        >
          <b-button
            variant="primary"
            @click="doSearch"
          >
            Search
          </b-button>
          <b-button
            class="ml-2"
            variant="outline-primary"
            @click="isRuleGroupSidebarActive = true"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Add New Group</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card no-body>
      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="records"
        primary-key="id"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
        :filter="searchKey"
        :filter-included-fields="filterOn"
      >

        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-tabs>
            <b-tab title="Rules">
              <b-tabs vertical nav-wrapper-class="nav-vertical">
                <t-tab v-for="item in row.item.rules" :key="item.id">
                  <b-card :title="item.name">
                    <b-row>
                      <b-col md="4">
                        <dl class="row">
                          <dt class="col-sm-5 text-right">
                            Rule ID :
                          </dt>
                          <dd class="col-sm-7">
                            {{ item.id }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-5 text-right">
                            Action :
                          </dt>
                          <dd class="col-sm-7">
                            {{ item.action }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-5 text-right">
                            Type :
                          </dt>
                          <dd class="col-sm-7">
                            {{ item.type }}
                          </dd>
                        </dl>
                      </b-col>

                      <b-col md="4">
                        <dl class="row">
                          <dt class="col-sm-5 text-right">
                            Target :
                          </dt>
                          <dd class="col-sm-7">
                            {{ item.check_value }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-5 text-right">
                            Duration Type :
                          </dt>
                          <dd class="col-sm-7">
                            {{ item.duration_type }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-5 text-right">
                            Duration Value :
                          </dt>
                          <dd class="col-sm-7">
                            {{ item.duration_value }}
                          </dd>
                        </dl>
                      </b-col>
                    </b-row>
                  </b-card>
                </t-tab>
                <!--                <b-tab title="Included">-->
                <!--                  <b-card-text>-->
                <!--                    {{ row.item.rules[0] }}-->
                <!--                  </b-card-text>-->
                <!--                </b-tab>-->
                <!--                <b-tab title="Excluded">-->
                <!--                  <b-card-text>-->
                <!--                    {{ row.item }}-->
                <!--                  </b-card-text>-->
                <!--                </b-tab>-->
              </b-tabs>
            </b-tab>
            <!--            <b-tab title="APPs">-->
            <!--              <b-tabs vertical nav-wrapper-class="nav-vertical">-->
            <!--                <b-tab title="Included">-->
            <!--                  <b-card-text>-->
            <!--                    {{ row.item }}-->
            <!--                  </b-card-text>-->
            <!--                </b-tab>-->
            <!--                <b-tab title="Excluded">-->
            <!--                  <b-card-text>-->
            <!--                    {{ row.item }}-->
            <!--                  </b-card-text>-->
            <!--                </b-tab>-->
            <!--              </b-tabs>-->
            <!--            </b-tab>-->
          </b-tabs>
        </template>

        <template #cell(enable)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.enable)}`"
          >
            {{ resolveStatusText(data.item.enable) }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <!--        <template #cell(opt)="data">-->
        <!--          <div-->
        <!--            v-if="data.item.enable"-->
        <!--            class="text-nowrap"-->
        <!--          >-->
        <!--            <feather-icon-->
        <!--              icon="PauseIcon"-->
        <!--              size="16"-->
        <!--              class="cursor-pointer mx-1"-->
        <!--              @click="changeStatus(data.item.id, 0)"-->
        <!--            />-->
        <!--          </div>-->
        <!--          <div-->
        <!--            v-if="!data.item.enable"-->
        <!--            class="text-nowrap"-->
        <!--          >-->
        <!--            <feather-icon-->
        <!--              icon="PlayIcon"-->
        <!--              size="16"-->
        <!--              class="cursor-pointer mx-1"-->
        <!--              @click="changeStatus(data.item.id, 1)"-->
        <!--            />-->
        <!--          </div>-->
        <!--        </template>-->

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BFormCheckbox, BButton, BCard, BRow, BCol, BTable, BBadge, BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'
import useAMLJs from './aml'
import RuleGroupSidebar from './RuleGroupSidebar.vue'

const {
  queryAmlRuleGroups,
  enableAmlRuleGroup,
  disableAmlRuleGroup,
} = useAMLJs()

export default {
  components: {
    RuleGroupSidebar,

    BFormInput,
    BFormCheckbox,
    BTabs,
    BTab,
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    vSelect,
  },
  data() {
    return {
      isRuleGroupSidebarActive: false,
      dataMeta: {
        from: 1,
        to: 1,
        of: 0,
      },
      perPage: 10,
      currentPage: 1,
      totalRecords: 0,
      ruleGroups: [],
      records: [],
      tableFields: [
        'show_details',
        'id',
        'name',
        'info',
        { key: 'enable', label: 'Status' },
        'create_time',
        // 'opt',
      ],
      ruleGroupStatusFilter: '',
      ruleGroupStatusOptions: [
        { label: 'All', value: '' },
        { label: 'Activated', value: true },
        { label: 'Deactivated', value: false },
      ],
      searchKey: '',
      filterOn: ['name', 'info'],
    }
  },
  created() {
    this.queryGroups()
  },
  methods: {
    queryGroups() {
      queryAmlRuleGroups(
        records => {
          this.ruleGroups = records
          this.records = records
        },
        fail => {
          showToast(this, 'Warning', `fetch list with ${fail}.`)
        },
      )
    },
    resolveStatusVariant(status) {
      if (status) {
        return 'success'
      }
      return 'danger'
    },
    resolveStatusText(status) {
      if (status) {
        return 'Activated'
      }
      return 'Deactivated'
    },
    doSearch() {
      if (this.ruleGroupStatusFilter !== '') {
        this.records = this.ruleGroups.filter(p => p.enable === this.ruleGroupStatusFilter)
      } else {
        this.records = this.ruleGroups
      }
    },
    changeStatus(id, status) {
      if (status === 1) {
        enableAmlRuleGroup(
          id,
          () => {
            showToast(this, 'Success', `No.${id} Enabled.`)
            this.queryGroups()
          },
          fail => {
            showToast(this, 'Warning', `Enable group fail with ${fail}.`)
          },
        )
      }
      if (status === 0) {
        disableAmlRuleGroup(
          id,
          () => {
            showToast(this, 'Success', `No.${id} Disabled.`)
            this.queryGroups()
          },
          fail => {
            showToast(this, 'Warning', `Disable group fail with ${fail}.`)
          },
        )
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
