<template>
  <b-sidebar
    id="rule-group-sidebar"
    :visible="isRuleGroupSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-rule-group-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Rule Group Info
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="simpleRules">
        <b-form class="p-2">

          <!-- Group Name -->
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="rule-group-name"
            >
              <b-form-input
                id="rule-group-name"
                v-model="ruleGroupInfo.name"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Group Desc -->
          <validation-provider
            #default="{ errors }"
            name="Description"
            rules="required"
          >
            <b-form-group
              label="Description"
              label-for="rule-group-info"
            >
              <b-form-input
                id="rule-group-info"
                v-model="ruleGroupInfo.info"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status -->
          <b-form-group
            label="Status"
            label-for="rule-group-status"
          >
            <v-select
              v-model="ruleGroupInfo.enable"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="rule-group-status"
            />
          </b-form-group>

          <!-- Rules -->
          <b-form-group
            label="Rules"
            label-for="rule-group-rules"
          >
            <v-select
              v-model="ruleGroupInfo.rules"
              :options="ruleOptions"
              value="id"
              label="name"
              multiple
              :reduce="val => val.id"
              input-id="rule-group-rules"
            />
          </b-form-group>

          <el-divider class="mt-3 mb-3" />

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isSubmitDisabled"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'
import useAMLJs from './aml'

const {
  queryAmlRules,
  createAmlRuleGroup,
} = useAMLJs()

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAppSidebarActive',
    event: 'update:is-app-sidebar-active',
  },
  props: {
    isRuleGroupSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ruleGroupInfo: {
        name: '',
        info: '',
        enable: true,
        rules: [],
      },
      rules: [],
      ruleOptions: [],
      error: '',

      statusOptions: [
        { label: 'Disable', value: false },
        { label: 'Enable', value: true },
      ],

      required,
    }
  },
  computed: {
    isSubmitDisabled() {
      return this.ruleGroupInfo.rules.length <= 0
    },
  },
  created() {
    this.queryRules()
  },
  methods: {
    queryRules() {
      queryAmlRules(
        records => {
          this.ruleOptions = records
        },
        fail => {
          showToast(this, 'Warning', `fetch list with ${fail}.`)
        },
      )
    },
    initValues() {
      this.ruleGroupInfo = {
        name: '',
        info: '',
        enable: true,
        rules: [],
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.addNew()
            }
          })
        }
      })
    },
    addNew() {
      createAmlRuleGroup(
        this.ruleGroupInfo,
        () => {
          showToast(this, 'Success', 'Created!')
          this.$emit('update:is-app-sidebar-active', false)
          this.$emit('updated')
        },
        fail => {
          showToast(this, 'Warning', `Create fail with ${fail}`)
        },
      )
      this.$emit('closeSidebar')
      this.initValues()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
